a {
  text-decoration: none;
  color: black;
}

* {
  margin: 0;
  padding: 0px;
  outline: none;
}

.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
}

.first_box {
  width: 30%;
  background-color: #D0EE11;
}

.nav_logo {
  margin-top: 20px;
  margin-left: 20px;
}

.alladinLogo {
  height: 35px;
}

.scond_box {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 62px;
}

.welcome_heading {
  font-size: 26px;
  font-weight: 600;
  color: #D0EE11;
  text-align: center;
}

.Email_input {
  width: 328px;
  height: 46px;
  margin-top: 11px;
  font-size: 14px;
  padding: 7px;
}

.name {
  margin-top: 30px;
}

.email_div {
  color: rgba(0, 0, 0, .45);
  font-weight: 600;
}

.password_div {
  margin-top: 10px;
  color: rgba(0, 0, 0, .45);
  font-weight: 600;
}

.btn_submit {
  margin-top: 13px;
  width: 328px;
  height: 46px;
  background-color: #D0EE11;
  border: none;
  font-weight: 600;
  border-radius: 4px;
}

.check_status {
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.status {
  font-size: calc(1.325rem + 0.9vw);
  color: rgb(0, 0, 0);
  font-weight: 600;
}


.P_tag {
  margin-top: 10px;
  text-align: center;
}


@media (min-width: 701px) and (max-width: 1024px) {


  .first_box {
    width: 40%;
    background-color: #D0EE11;
  }
}

.flex_div {
  display: flex;
}

.Email_input_1 {
  width: 155px;
  height: 46px;
  margin-top: 10px;
  font-size: 14px;
  padding: 7px;
}

.Email_input_2 {
  width: 155px;
  height: 46px;
  margin-top: 10px;
  font-size: 14px;
  padding: 7px;

}

.password_1 {
  margin-left: 17px;

}

.validpasswword {
  width: 325px;
  display: flex;
  list-style: none;
  justify-content: space-between;
  font-size: 13px;
  margin-top: 5px;
}

.uppercasee {
  list-style: none;
  font-size: 13px;
}

.true_condition {
  color: rgba(40, 42, 53, .3);
}

.passwordmatches {
  font-size: 12px;
  padding-top: 5px;
  color: red;
}

.noaccount {
  font-size: 12px;
  text-align: center;
  color: #909393;
  margin-top: 12px;
}

.error_div {
  font-size: 13px;
  color: red;
  font-weight: 600;
  padding-top: 5px;
}

.Email_check {
  color: red;
  font-size: 12px;
  padding-top: 5px;
}


@media (min-width: 350px) and (max-width: 700px) {

  .first_box {
    display: none;
  }

  .scond_box {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.header {
  transition: all 0.5s;
  z-index: 997;
  height: 70px;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
  padding-left: 8px;
}

.header .search-bar {
  min-width: 360px;
  padding: 0 20px;
  margin: auto;
}

#main {
  margin-top: 60px;
  padding: 20px 30px;
  transition: all 0.3s;
}

#footer {
  margin-top: auto;
  /* padding-top: 24px; */
  width: 100%;
}

@media (max-width: 979px) {
  #main {
    padding: 20px;
  }
}

.bi {
  display: flex;
}

/*--------------------------------------------------------------
# Dropdown menus
--------------------------------------------------------------*/
.dropdown-menu {
  border-radius: 4px;
  padding: 10px 0;
  -webkit-animation-name: dropdown-animate;
  animation-name: dropdown-animate;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 0;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}

.dropdown-menu .dropdown-header {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.dropdown-menu .dropdown-divider {
  color: #a5c5fe;
  margin: 0;
}

.dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.dropdown-menu .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f6f9ff;
}

@media (min-width: 768px) {
  .dropdown-menu-arrow::before {
    content: "";
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 20px;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
  }
}

@-webkit-keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.logo {
  line-height: 1;
}

@media (min-width: 979px) {
  .logo {
    width: 250px;
  }
}

.logo img {
  max-height: 26px;
  margin-right: 6px;
}

.logo span {
  font-size: 26px;
  font-weight: 700;
  color: var(--primary-color);
  font-family: "Poppins", sans-serif;
}

/*--------------------------------------------------------------
# Header Nav
--------------------------------------------------------------*/
.header-nav ul {
  list-style: none;
}

.header-nav>ul {
  margin: 0;
  padding: 0;
}

.header-nav .nav-icon {
  font-size: 22px;
  color: var(--primary-color);
  margin-right: 25px;
  position: relative;
}

.header-nav .nav-profile {
  color: var(--primary-color);
}

/* .header-nav .nav-profile img {
  max-height: 36px;
} */

.header-nav .nav-profile span {
  font-size: 14px;
  font-weight: 600;
}

.header-nav .badge-number {
  position: absolute;
  inset: -8px -8px auto auto;
  font-weight: normal;
  font-size: 12px;
  padding: 3px 6px;
}

.header-nav .notifications {
  inset: 8px -15px auto auto !important;
}

.header-nav .notifications .notification-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .notifications .notification-item i {
  margin: 0 20px 0 10px;
  font-size: 24px;
}

.header-nav .notifications .notification-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.header-nav .notifications .notification-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .notifications .notification-item:hover {
  background-color: #f6f9ff;
}

.header-nav .profile {
  min-width: 240px;
  padding-bottom: 0;
  top: 8px !important;
}

.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444444;
}

.header-nav .profile .dropdown-header span {
  font-size: 14px;
}

.header-nav .profile .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.header-nav .profile .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.header-nav .profile .dropdown-item:hover {
  background-color: #f6f9ff;
}

.logout-link:hover {
  background-color: var(--bs-red) !important;
  color: #ffffff;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar {
  position: fixed;
  top: 58px;
  left: 0;
  bottom: 0;
  width: 17%;
  z-index: 996;
  transition: all 0.3s;
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  /* box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
   */
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);

  background-color: #fff;
  display: flex;
  flex-direction: column;
}

@media (max-width: 979px) {
  .sidebar {
    left: -300px;
  }
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

@media (min-width: 979px) {
  #main {
    margin-left: 300px;
  }
}

@media (max-width: 979px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 979px) {
  .toggle-sidebar #main {
    margin-left: 0 !important;
  }

  .toggle-sidebar .sidebar {
    left: -300px;
  }
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
  cursor: pointer;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #899bbd;
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  /* font-size: 15px; */
  /* font-weight: 600; */
  color: var(--secondary-color);
  transition: 0.3;
  background: #f6f9ff;
  padding: 0px 9px;
  border-radius: 4px;
}

.sidebar-nav .nav-link i {
  font-size: 16px;
  margin-right: 10px;
  color: var(--secondary-color);
}

.sidebar-nav .nav-link.collapsed,
.sidebar-nav .nav-link:not(.active) {
  color: var(--primary-color);
  background: #fff;
}

.sidebar-nav .nav-link.collapsed i,
.sidebar-nav .nav-link:not(.active) i {
  color: #899bbd;
}

.sidebar-nav .nav-link:hover {
  color: var(--secondary-color);
  background: #f6f9ff;
}

.sidebar-nav .nav-link:hover i {
  color: var(--secondary-color);
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  /* font-size: 14px; */
  /* font-weight: 600; */
  color: var(--primary-color);
  transition: 0.3;
  padding: 8px 0 6px 16px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color: var(--secondary-color);
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-left: 10px;
  cursor: pointer;
  color: var(--primary-color);
}

.header .search-bar {
  min-width: 360px;
  padding: 0 20px;
}

@media (max-width: 767px) {
  .header .search-bar {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
    background: white;
    z-index: 9999;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
  }

  .header .search-bar-show {
    top: 60px;
    visibility: visible;
    opacity: 1;
  }
}

.header .search-form {
  width: 100%;
}

.header .search-form input {
  border: 0;
  font-size: 14px;
  color: var(--primary-color);
  border: 1px solid rgba(1, 41, 112, 0.2);
  padding: 7px 38px 7px 8px;
  border-radius: 3px;
  transition: 0.3s;
  width: 100%;
}

.header .search-form input:focus,
.header .search-form input:hover {
  outline: none;
  box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
  border: 1px solid rgba(1, 41, 112, 0.3);
}

.header .search-form button {
  border: 0;
  padding: 0;
  margin-left: -30px;
  background: none;
}

.header .search-form button i {
  color: var(--primary-color);
}

.usermanagment {
  width: 30px;
}

.usermangment_div {
  cursor: pointer;
  display: flex;
  margin-left: 10px;
  margin-top: 21px;
}

.text_div {
  margin-left: 13px;
}

span.text-capitalize {
  padding-left: 16px;
}

svg {
  width: 30px;
}

.component_dashboard {
  width: 80%;
  float: right;
  margin-right: 20px;
}
.css-v4u5dn-MuiInputBase-root-MuiInput-root{
  margin-left: 40px;
  width: 500px;
}
.css-vulnvf{
  padding: 20px;
}